/* headerStyles.css */

@keyframes gradientAnimation {
    0% {
      background-image: linear-gradient(45deg, #ff7e5f, #feb47b);
    }
    100% {
      background-image: linear-gradient(45deg, #76b2fe, #b69efe);
    }
  }
  
  .header {
    animation: gradientAnimation 0.5s infinite alternate;
  }
  