body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-gallery-icon {
  color: white; /* Example to change icon color */
}

.image-gallery-slide-wrapper .image-gallery-slide {
  transition: transform 450ms ease-out; /* Ensures smooth sliding */
}

.responsive-iframe {
  width: 100%;
  height: 100%; /* Adjust based on your form's typical height */
  border: 0;
}

@media (max-width: 600px) {
  .responsive-iframe {
      height: 800px; /* Increase height for smaller screens */
  }
}