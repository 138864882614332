@font-face {
    font-family: 'Omnes';
    src: url('./Omnes_Black.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Omnes';
    src: url('./Omnes\ Bold\ Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
